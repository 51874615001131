<template>
  <page-body class="mx-lg" :bg="nobg">
    <header id="header">
      <div class="container">
        <div class="row" style="flex-wrap: wrap">
          <div class="col-5 col">
            <svg-logo></svg-logo>
          </div>
          <div class="col-7 col logotext">
            <span class="logotext1 lower">{{ i18n['login'].tcThe }}</span>
            <span class="logotext2">{{ i18n['login'].tcConnection }}</span>
          </div>
        </div>
      </div>
    </header>
    <section class="container" v-if="this.translations.tcServiceMessageActive == 1">
      <div class="card col maincard notify-warning">
        <div class="row" style="margin-top: 1rem">
          <div class="col col-12" style="margin: auto">
            <div class="notify-warning" v-html="this.translations.tcServiceMessage"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="container" v-if="showLogin">
      <div class="card col maincard" style>
        <div class="row" style="margin-top: 1rem">
          <div class="col col-6" style="margin: auto">
            <div class="cardtopsection" style>
              <div class>
                <h5 class="signin upper">{{ i18n['login'].tcSignIn }}</h5>
                <p class="card-text">
                  <b-form-input id="user_id" v-model="userid" :placeholder="i18n['login'].tcEmailAddress"
                    class="g-select login-input" type="text"></b-form-input>
                  <br />
                  <VuePassword v-model="password" id="password" :placeholder="i18n['login'].tcPassword"
                    :disableStrength="true" :classes="'g-select login-input'" type="password"
                    @keyup.enter="authenticate" />
                </p>
                <div class="row">
                  <div class="col col-7">
                    <div class="mb-3 remembermediv">
                      <input id="rememberme" type="checkbox" name="rememberme" autocomplete="off"
                        class="remembermecheckbox mr-2" v-model="rememberme" />
                      <label for="rememberme" class="remembermelabel">{{ i18n['login'].tcRememberEmail }}</label>
                    </div>
                  </div>
                  <div class="col col-5" style="text-align: right">
                    <a target="_self" href="#" class="btn btn-link fun-link"
                      @click.prevent="showform('showRecoverUsername1')">{{ i18n['login'].tcForgotUser }}</a>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-12">
                    <a target="_self" href="#" class="btn btn-dark flex-0 mr-2 btnsignin"
                      @click.prevent="authenticate">{{ i18n['login'].tcSignIn }}</a>
                    <a target="_self" href="#" class="btn btn-light btngreyyellow"
                      @click.prevent="showform('showResetPassword1')">{{ i18n['login'].tcResetPassword }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 4rem">
          <div class="col col-2"></div>
          <div class="col col-1 col-regsidecol"></div>
          <div class="col col-6 col-regmaincol">
            <div class="cardbottomsection" style>
              <div class>
                <h5 class="nreg">{{ i18n['login'].tcNeverRegistered }}</h5>
                <span class="nregdesc">
                  {{ i18n['login'].tcIfYouAreNewTo }}
                  <i>{{ i18n['login'].tcTheConnection }}</i> {{ i18n['login'].tcPleaseComplete }}
                </span>
                <br />
                <br />
                <p class="card-text">
                  <b-form-input id="memberno" v-model="memberno" :placeholder="i18n['login'].tcMemberNumber"
                    class="form-control col-left-input flex-1 g-select" type="text"></b-form-input>
                  <br />
                  <b-form-input id="campno" v-model="campno" :placeholder="i18n['login'].tcCampNumber"
                    class="form-control col-left-input flex-1 g-select" type="text"></b-form-input>
                </p>
                <div class="row" style="margin-top: 10px">
                  <div class="col col-12">
                    <a target="_self" href="#" class="btn btn-dark flex-0 mr-2 btngreyblue" style
                      @click.prevent="registerStep1">{{ i18n['login'].tcRegister }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-1 col-regsidecol"></div>
          <div class="col col-2"></div>
        </div>
      </div>
    </section>
    <section class="container" v-if="showTwoFactor">
      <div class="card col maincard" style>
        <div class="row" style="margin-top: 1rem">
          <div class="col col-6" style="margin: auto">
            <div class="cardtopsection" style>
              <div class>
                <h5 class="signin">{{ i18n['login'].tc2StepVerification }}</h5>
                <span class="nregdesc">
                  {{ i18n['login'].tcWeNeedToConfirm }}
                  <i>{{ i18n['login'].tcTheConnection }}</i>
                  , {{ i18n['login'].tcPleaseEnter }} {{ userid }} {{ i18n['login'].tcForYourVerification }}.
                </span>
                <p class="card-text">
                  <b-form-input id="twofactor_id" v-model="twofactorval"
                    :placeholder="i18n['login'].tcAuthenticationCode"
                    class="form-control co`l-left-input flex-1 g-select" type="text"></b-form-input>
                </p>
                <div class="row">
                  <div class="col col-7">
                    <a target="_self" href="#" class="btn btn-dark flex-0 mr-2 btnsignin"
                      @click.prevent="authenticatetwofactor">{{ i18n['login'].tcVerifyCode }}</a>
                  </div>
                  <div class="col col-5" style="text-align: right">
                    <a target="_self" href="#" class="btn btn-link retry-link" @click.prevent="retrylogin">{{
                        i18n['login'].tcRetryLogin
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container" v-if="showRegister">
      <div class="card col maincard" style>
        <b-form validated @submit.prevent>
          <div class="row" style="margin-top: 1rem">
            <div class="col col-8" style="margin: auto">
              <div class="cardtopsection" style>
                <div class>
                  <h5 class="signin">{{ i18n['login'].tcContinueRegistration }}</h5>
                  <span class="nregdesc">
                    {{ i18n['login'].tcPleaseVerify }}
                    <br />
                    <br />
                  </span>
                  <div class="row memberdata">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcFirstName }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ register_data.firstname }}</p>
                    </div>
                  </div>
                  <div class="row memberdata">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcLastName }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ register_data.lastname }}</p>
                    </div>
                  </div>
                  <div class="row memberdata">
                    <div class="col col-6">
                      <p class="card-text bold caps">{{ i18n['login'].tcMemberNumber }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ register_data.memberno }}</p>
                    </div>
                  </div>
                  <div class="row memberdata">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcCampNumber }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ register_data.campno }}</p>
                    </div>
                  </div>
                  <span class="nregdesc">
                    <br />{{ i18n['login'].tcSelectAQuestion }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcSecurityQuestion1"
                        label-for="question1" description>
                        <b-form-select id="question1" class="form-control flex-1 g-select mb-2 mr-sm-2 mb-sm-0s"
                          :options="optionsQuestions1" v-model="register_data.question1" required></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcAnswer" label-for="answer1"
                        description>
                        <b-form-input id="answer1" v-model="register_data.answer1" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcSecurityQuestion2"
                        label-for="question2" description>
                        <b-form-select id="question2" class="form-control g-select mb-2 mr-sm-2 mb-sm-0s"
                          :options="optionsQuestions2" v-model="register_data.question2" required></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcAnswer" label-for="answer2"
                        description>
                        <b-form-input id="answer2" v-model="register_data.answer2" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcSecurityQuestion3"
                        label-for="question3" description>
                        <b-form-select id="question3" class="form-control g-select mb-2 mr-sm-2 mb-sm-0s"
                          :options="optionsQuestions3" v-model="register_data.question3" required></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcAnswer" label-for="answer3"
                        description>
                        <b-form-input id="answer3" v-model="register_data.answer3" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <span class="nregdesc">
                    <br />{{ i18n['login'].tcCreateANewPassword }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcEmailType" label-for="emailtype"
                        description>
                        <b-form-select id="emailtype" class="form-control g-select mb-2 mr-sm-2 mb-sm-0s"
                          :options="optionsEmailTypes" v-model="register_data.emt_key" required></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcEmailAddress2" label-for="emailaddress"
                        description>
                        <b-form-input id="emailaddress" v-model="register_data.emailaddress" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcConfirmEmailAddress"
                        label-for="confirmemailaddress" description>
                        <b-form-input id="confirmemailaddress" v-model="register_data.confirmemailaddress" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcPassword2" label-for="password"
                        description>
                        <b-form-input id="password" v-model="register_data.password" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="password"
                          required></b-form-input>
                        <div v-if="passwordValidation.errors.length > 0" class="hints">
                          <h2>{{ i18n['login'].tcHints }}</h2>
                          <p v-bind:key="error" v-for="error in passwordValidation.errors">
                            {{ error }}
                          </p>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcConfirmPassword"
                        label-for="confirmpassword" description>
                        <b-form-input id="confirmpassword" v-model="register_data.confirmpassword" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="password"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="col col-7">
                      <b-button type="submit" variant="primary" class="btn btn-dark flex-0 mr-2 btngreyblue" style
                        @click="registerStep2">{{ i18n['login'].tcConfirm }}</b-button>
                    </div>
                    <div class="col col-5" style="text-align: right">
                      <a target="_self" href="#" class="btn btn-link retry-link" @click.prevent="retrylogin">{{
                          i18n['login'].tcCancel
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="container" v-if="showRecoverUsername1">
      <div class="card col maincard" style>
        <div class="row" style="margin-top: 1rem">
          <div class="col col-6" style="margin: auto">
            <div class>
              <h5 class="nreg">{{ i18n['login'].tcRecoverUserName }}</h5>
              <span class="nregdesc">{{ i18n['login'].tcEnterYourMember }}</span>
              <br />
              <br />
              <p class="card-text">
                <b-form-input id="rumemberno" v-model="recoverusername_data.memberno"
                  :placeholder="i18n['login'].tcMemberNumber" class="form-control col-left-input flex-1 g-select"
                  type="text"></b-form-input>
                <br />
                <b-form-input id="rucampno" v-model="recoverusername_data.campno"
                  :placeholder="i18n['login'].tcCampNumber" class="form-control col-left-input flex-1 g-select"
                  type="text"></b-form-input>
              </p>
              <div class="row" style="margin-top: 10px">
                <div class="col col-7">
                  <a target="_self" href="#" class="btn btn-dark flex-0 mr-2 btngreyblue"
                    @click.prevent="recoverusernameStep1">{{ i18n['login'].tcContinue }}</a>
                </div>
                <div class="col col-5" style="text-align: right">
                  <a target="_self" href="#" class="btn btn-link retry-link" @click.prevent="retrylogin">{{
                      i18n['login'].tcCancel
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container" v-if="showRecoverUsername2">
      <div class="card col maincard" style>
        <b-form validated @submit.prevent>
          <div class="row" style="margin-top: 1rem">
            <div class="col col-8" style="margin: auto">
              <div class="cardtopsection" style>
                <div class>
                  <h5 class="signin">{{ i18n['login'].tcConfirmAndRecover }}</h5>
                  <span class="nregdesc">
                    {{ i18n['login'].tcPleaseVerifyRecovery }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcFirstName }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">
                        {{ recoverusername_data.firstname }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcLastName }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">
                        {{ recoverusername_data.lastname }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcMemberNumber }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">
                        {{ recoverusername_data.memberno }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-6 bold">
                      <p class="card-text">{{ i18n['login'].tcCampNumber }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ recoverusername_data.campno }}</p>
                    </div>
                  </div>
                  <span class="nregdesc">
                    <br />{{ i18n['login'].tcAnswerTheFollowing }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-12">
                      <p class="card-text">
                        {{ recoverusername_data.question }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" :label="i18n['login'].tcAnswer" label-for="ruanswer"
                        description>
                        <b-form-input id="ruanswer" v-model="recoverusername_data.answer" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="col col-7">
                      <b-button type="submit" variant="primary" class="btn btn-dark flex-0 mr-2 btngreyblue"
                        @click="recoverusernameStep2">{{ i18n['login'].tcConfirm }}</b-button>
                    </div>
                    <div class="col col-5" style="text-align: right">
                      <a target="_self" href="#" class="btn btn-link retry-link" @click.prevent="retrylogin">{{
                          i18n['login'].tcCancel
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="container" v-if="showResetPassword1">
      <div class="card col maincard" style>
        <div class="row" style="margin-top: 1rem">
          <div class="col col-6" style="margin: auto">
            <div class>
              <h5 class="nreg">{{ i18n['login'].tcResetPassword }}</h5>
              <span class="nregdesc">{{ i18n['login'].tcEnterYourMember }}</span>
              <br />
              <br />
              <p class="card-text">
                <b-form-input id="psmemberno" v-model="resetpassword_data.memberno"
                  :placeholder="i18n['login'].tcMemberNumber" :trim="trim"
                  class="form-control col-left-input flex-1 g-select" type="text"></b-form-input>
                <br />
                <b-form-input id="pscampno" v-model="resetpassword_data.campno"
                  :placeholder="i18n['login'].tcCampNumber" :trim="trim"
                  class="form-control col-left-input flex-1 g-select" type="text"></b-form-input>
              </p>
              <div class="row" style="margin-top: 10px">
                <div class="col col-7">
                  <a target="_self" href="#" class="btn btn-dark flex-0 mr-2 btngreyblue" style
                    @click.prevent="resetpasswordStep1">{{ i18n['login'].tcContinue }}</a>
                </div>
                <div class="col col-5" style="text-align: right">
                  <a target="_self" href="#" class="btn btn-link retry-link" @click.prevent="retrylogin">{{
                      i18n['login'].tcCancel
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container" v-if="showResetPassword2">
      <div class="card col maincard" style>
        <b-form validated @submit.prevent>
          <div class="row" style="margin-top: 1rem">
            <div class="col col-8" style="margin: auto">
              <div class="cardtopsection" style>
                <div class>
                  <h5 class="signin">{{ i18n['login'].tcConfirmAndReset }}</h5>
                  <span class="nregdesc">
                    {{ i18n['login'].tcPleaseVerifyChange }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcFirstName }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">
                        {{ resetpassword_data.firstname }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcLastName }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ resetpassword_data.lastname }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcMemberNumber }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ resetpassword_data.memberno }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <p class="card-text bold">{{ i18n['login'].tcCampNumber }}:</p>
                    </div>
                    <div class="col col-6">
                      <p class="card-text">{{ resetpassword_data.campno }}</p>
                    </div>
                  </div>
                  <span class="nregdesc">
                    <br />{{ i18n['login'].tcAnswerTheFollowing }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-12">
                      <p class="card-text">{{ resetpassword_data.question }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" label="Answer:" label-for="pwanswer" description>
                        <b-form-input id="pwanswer" v-model="resetpassword_data.answer" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="text"
                          :trim="trim" required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <span class="nregdesc">
                    <br />{{ i18n['login'].tcCreateANewPassword }}
                    <br />
                    <br />
                  </span>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" label="Password:" label-for="pwpassword" description>
                        <b-form-input id="pwpassword" v-model="resetpassword_data.password" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="password"
                          required></b-form-input>
                        <div v-if="resetpasswordValidation.errors.length > 0" class="hints">
                          <h2>{{ i18n['login'].tcHints }}</h2>
                          <p v-bind:key="error" v-for="error in resetpasswordValidation.errors">
                            {{ error }}
                          </p>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-12">
                      <b-form-group class="form-element" label="Confirm Password:" label-for="confirmpassword"
                        description>
                        <b-form-input id="pwonfirmpassword" v-model="resetpassword_data.confirmpassword" placeholder
                          class="form-control col-left-input flex-1 g-select mb-2 mr-sm-2 mb-sm-0s" type="password"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="col col-7">
                      <b-button type="submit" variant="primary" class="btn btn-dark flex-0 mr-2 btngreyblue" style
                        @click="resetpasswordStep2">{{ i18n['login'].tcConfirm }}</b-button>
                    </div>
                    <div class="col col-5" style="text-align: right">
                      <a target="_self" href="#" class="btn btn-link retry-link" @click.prevent="retrylogin">{{
                          i18n['login'].tcCancel
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </section>
  </page-body>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { breakpoints } from '../mixins/breakpoints'
import { Loader } from '@/mixins/Loader'
import Logo from '../assets/svgs/logo.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import { translationMixin } from '../mixins/translationMixin'
import VuePassword from 'vue-password'

export default {
  name: 'login',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          login: {
            tc2StepVerification: '2-Step Verification',
            tcAnswer: 'Answer:',
            tcAnswerTheFollowing: 'Answer the following question.',
            tcAuthenticationCode: 'Authentication Code',
            tcCampNumber: 'CAMP NUMBER',
            tcCancel: 'Cancel',
            tcConfirm: 'Confirm',
            tcConfirmAndRecover: 'Confirm & Recover User Name',
            tcConfirmAndReset: 'Confirm & Reset Password',
            tcConfirmEmailAddress: 'Confirm Email Address:',
            tcConfirmPassword: 'Confirm Password:',
            tcConnection: 'Connection',
            tcContinue: 'CONTINUE',
            tcContinueRegistration: 'Continue Registration',
            tcCreateANewPassword: 'Create a new password in the boxes below:',
            tcEightCharactersMinimum: '8 characters minimum.',
            tcEmailAddress: 'EMAIL ADDRESS',
            tcEmailAddress2: 'Email Address:',
            tcEmailType: 'Email Type:',
            tcEnterYourMember: 'Enter your member number and camp number to continue.',
            tcFailedPasswordConstraints: 'The password must conform to the hints listed on the form.',
            tcFirstName: 'First Name',
            tcForgotUser: 'Forgot User Name?',
            tcForYourVerification: 'for your verification.',
            tcHints: 'Hints',
            tcIfYouAreNewTo: 'If you are new to',
            tcInformationIsRequired:
              'Information is required to recover your username. Please complete the fields until all are checked green.',
            tcInformationRequiredToReset:
              'Information is required to reset password. Please complete the fields until all are checked green.',
            tcInvalidAuthentication: 'Invalid Authentication.',
            tcLastName: 'Last Name',
            tcMemberNumber: 'MEMBER NUMBER',
            tcNeverRegistered: 'NEVER REGISTERED?',
            tcOk: 'Ok',
            tcOneLowercaseLetter: 'One lowercase letter required.',
            tcOneNumberRequired: 'One number required.',
            tcOneUppercaseLetter: 'One uppercase letter required.',
            tcPassword: 'PASSWORD',
            tcPassword2: 'Password:',
            tcPasswordsDoNotMatch: 'Passwords do not match, please correct.',
            tcPleaseComplete: 'please complete the form below',
            tcPleaseEnter: 'please enter the code sent to',
            tcPleaseTryDifferent: 'Please try a different password.',
            tcPleaseVerify: 'Please verify the information below, then confirm to complete the registration process.',
            tcPleaseVerifyChange:
              'Please verify the information below, then confirm to complete the change password process.',
            tcPleaseVerifyRecovery:
              'Please verify the information below, then confirm to complete the recovery process.',
            tcRecoverUserName: 'Recover User Name',
            tcRegister: 'REGISTER',
            tcRegistrationEmailsDoNotMatch: 'Registration emails do not match, please correct.',
            tcRegistrationInfoRequired:
              'Registration information is required to register. Please complete the fields until all are checked green.',
            tcRegistrationPasswordsDoNotMatch: 'Registration passwords do not match, please correct.',
            tcRememberEmail: 'Remember Email Address',
            tcRetryLogin: 'Retry Login?',
            tcResetPassword: 'RESET PASSWORD',
            tcSelectAQuestion:
              'Select a question from each of the menu boxes below and provide an answer. Security questions are used to verify your registration in the event you forget or need to reset your password.',
            tcSelectAType: 'Select a type',
            tcSecurityQuestion1: 'Security Question #1:',
            tcSecurityQuestion2: 'Security Question #2:',
            tcSecurityQuestion3: 'Security Question #3:',
            tcSelectSecurityQuestion: 'Select a security question',
            tcSignIn: 'SIGN IN',
            tcThankYouForRegistering: 'Thank You for registering. You may now Sign In.',
            tcThe: 'The',
            tcTheConnection: 'theConnection',
            tcVerifyCode: 'Verify Code',
            tcWeNeedToConfirm: 'We need to confirm your identity before you can proceed to',
            tcYouHaveAlreadyRegistered: 'You have already registered.',
            tcYouHaveNeverRegistered: 'You have never registered. Please register now.',
            tcYouHaveNotRegistered: 'You have not registered. Please register now.',
            tcYourPasswordHasBeenReset: 'Your password has been reset. You may now Sign In.',
            tcYourUserNameRecovered: 'Your user name has been recovered. You may now Sign In.',
          },
        }
      },
    },
  },
  data() {
    return {
      translations: {},
      nobg: false,
      showLogin: true,
      showTwoFactor: false,
      showRegister: false,
      showResetPassword1: false,
      showResetPassword2: false,
      showRecoverUsername1: false,
      showRecoverUsername2: false,
      password: '',
      userid: localStorage.userid,
      rememberme: Boolean(localStorage.rememberme),
      memberno: '',
      campno: '',
      twofactorval: '',
      submitted: false,
      recoverusername_data: {
        firstname: '',
        lastname: '',
        memberno: '',
        campno: '',
        question: null,
        answer: '',
        emailaddress: '',
        chosenQuestion: 0,
      },
      resetpassword_data: {
        firstname: '',
        lastname: '',
        memberno: '',
        campno: '',
        question: null,
        answer: '',
        emailaddress: '',
        password: '',
        confirmpassword: '',
        chosenQuestion: 0,
      },
      register_data: {
        firstname: '',
        lastname: '',
        memberno: '',
        campno: '',
        question1: null,
        answer1: '',
        question2: null,
        answer2: '',
        question3: null,
        answer3: '',
        emt_key: null,
        emailaddress: '',
        confirmemailaddress: '',
        password: '',
        confirmpassword: '',
      },
      user_data: {
        id: '',
        name: '',
        location: '',
        camp_key: '',
        state_key: '',
        ind_key: '',
        lng_key: '',
        mnu_key: '',
        org_key: '',
        temp_org_key: '8325C7F3-173C-4588-AAEA-808256F853AC',
      },
      passwordrules: [
        { message: this.i18n['login'].tcOneLowercaseLetter, regex: /[a-z]+/ },
        { message: this.i18n['login'].tcOneUppercaseLetter, regex: /[A-Z]+/ },
        { message: this.i18n['login'].tcEightCharactersMinimum, regex: /^.{8,}$/ },
        { message: this.i18n['login'].tcOneNumberRequired, regex: /[0-9]+/ },
      ],
      trim: true
    }
  },
  mixins: [breakpoints, Loader, translationMixin],
  components: {
    svgLogo: Logo,
    pageBody: pageBody,
    VuePassword,
  },
  computed: {
    ...mapGetters({
      convertedAuthQuestions: 'user/convertedAuthQuestions',
      convertedEmailFormOptions: 'membership/convertedEmailFormOptions',
      individualProfile: 'membership/individualProfile',
      loginRedirectPath: 'user/loginRedirectPath',
      membersAuthQuestionsAndAnswers: 'user/membersAuthQuestionsAndAnswers',
      userLogin: 'user/userLogin',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userVerification: 'user/userVerification',
    }),
    optionsQuestions1() {
      return [
        { value: null, text: this.i18n['login'].tcSelectSecurityQuestion, disabled: true },
        ...this.convertedAuthQuestions.questions.filter((item) => {
          return item.value > 0 && item.value < 6
        }),
      ]
    },
    optionsQuestions2() {
      return [
        { value: null, text: this.i18n['login'].tcSelectSecurityQuestion, disabled: true },
        ...this.convertedAuthQuestions.questions.filter((item) => {
          return item.value > 5 && item.value < 11
        }),
      ]
    },
    optionsQuestions3() {
      return [
        { value: null, text: this.i18n['login'].tcSelectSecurityQuestion, disabled: true },
        ...this.convertedAuthQuestions.questions.filter((item) => {
          return item.value > 10
        }),
      ]
    },
    optionsEmailTypes() {
      return [
        { value: null, text: this.i18n['login'].tcSelectAType, disabled: true },
        ...this.convertedEmailFormOptions.emailTypes,
      ]
    },
    passwordValidation() {
      let errors = []
      for (let condition of this.passwordrules) {
        if (!condition.regex.test(this.register_data.password)) {
          errors.push(condition.message)
        }
      }
      return { valid: errors.length === 0, errors }
    },
    resetpasswordValidation() {
      let errors = []
      for (let condition of this.passwordrules) {
        if (!condition.regex.test(this.resetpassword_data.password)) {
          errors.push(condition.message)
        }
      }
      return { valid: errors.length === 0, errors }
    },
  },
  methods: {
    ...mapActions({
      getAllowedControls: 'user/getAllowedControls',
      getAuthQuestions: 'user/getAuthQuestions',
      getMembersAuthQuestionsAndAnswers: 'user/getMembersAuthQuestionsAndAnswers',
      getUserLogin: 'user/getUserLogin',
      getUserLoginTwoFactor: 'user/getUserLoginTwoFactor',
      getUserPasswordReset: 'user/getUserPasswordReset',
      getUserRegistered: 'user/getUserRegistered',
      getUserVerification: 'user/getUserVerification',
      getUserVerifyQuestionAnswer: 'user/getUserVerifyQuestionAnswer',
      loadEmailFormOptions: 'membership/loadEmailFormOptions',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      setAuthenticated: 'user/setAuthenticated',
      setLoadingStatus: 'menu/setLoadingStatus',
      setLoginRedirectPath: 'user/setLoginRedirectPath',
      setOfficerToolbarDefault: 'home/setOfficerToolbarDefault',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setUser: 'user/setUser',
    }),
    showform(form) {
      this.showLogin = false
      this.showTwoFactor = false
      this.showRegister = false
      this.showResetPassword1 = false
      this.showResetPassword2 = false
      this.showRecoverUsername1 = false
      this.showRecoverUsername2 = false
      switch (form) {
        case 'showLogin':
          this.showLogin = true
          break
        case 'showTwoFactor':
          this.showTwoFactor = true
          break
        case 'showRegister':
          this.showRegister = true
          break
        case 'showResetPassword1':
          this.showResetPassword1 = true
          break
        case 'showResetPassword2':
          this.showResetPassword2 = true
          break
        case 'showRecoverUsername1':
          this.showRecoverUsername1 = true
          break
        case 'showRecoverUsername2':
          this.showRecoverUsername2 = true
          break
      }
      return true
    },
    setLSRememberMe() {
      if (this.rememberme) {
        localStorage.userid = this.userid
        localStorage.rememberme = '1'
      } else {
        localStorage.userid = ''
        localStorage.rememberme = ''
      }
    },
    async authenticate() {
      this.showLoader()
      this.setLSRememberMe()
      // save the camp and user ids to the user store
      var payload = {
        Username: this.userid,
        Password: this.password,
      }
      await this.getUserLogin(payload)
        .then(async (result) => {
          if (result == true && this.userLogin.isAuthorized) {
            await this.setUserDataFromUserLogin()
            this.setUser(this.user_data)
            this.setOfficerToolbarDefault({
              ind_key: this.userLogin.indkey,
              org_key: this.userLogin.campkey,
              lng_key: this.userLogin.languagekey,
            })
            this.setAuthenticated(true)
            this.getAllowedControls()
            this.closeLoader()
            this.redirectUser()
          } else if (result == true && !this.userLogin.isAuthorized) {
            this.showform('showTwoFactor')
            this.closeLoader()
          } else {
            this.$swal({
              icon: 'error',
              text: this.i18n['login'].tcInvalidAuthentication,
              confirmButtonText: this.i18n['login'].tcOk,
            }).then((result) => {
              this.closeLoader()
              return
            })
          }
        })
        .catch((error) => {
          this.closeLoader()
          return
        })
    },
    async setUserDataFromUserLogin() {
      await (async () => {
        this.user_data.location = this.userLogin.campname
        this.user_data.camp_key = this.userLogin.campkey
        this.user_data.state_key = this.userLogin.statekey
        this.user_data.ind_key = this.userLogin.indkey
        this.user_data.mnu_key = this.userLogin.indkey
        this.user_data.org_key = this.userLogin.campkey
        this.user_data.name = this.userLogin.name
        this.user_data.id = this.userLogin.campnumber
        this.user_data.lng_key = this.userLogin.languagekey
      })()
    },
    async setUserDataFromUserVerification() {
      await (async () => {
        this.user_data.location = this.userVerification.org_name
        this.user_data.camp_key = this.userVerification.cmp_org_key
        this.user_data.ind_key = this.userVerification.ind_key
        this.user_data.mnu_key = this.userVerification.indkey
        this.user_data.org_key = this.userVerification.cmp_org_key
        this.user_data.name = this.userVerification.usr_login_id
        this.user_data.id = this.userVerification.cmp_number
        this.user_data.lng_key = this.userVerification.ind_lng_key
      })()
    },
    async authenticatetwofactor() {
      this.showLoader()
      // save the camp and user ids to the user store
      var payload = {
        rndnum: this.twofactorval,
      }
      let result = await this.getUserLoginTwoFactor(payload)
      if (result == true && this.userLogin.isAuthorized) {
        await this.setUserDataFromUserLogin()
        await this.setUser(this.user_data)
        this.closeLoader()
        await this.setAuthenticated(true)
        await this.getAllowedControls()
        await this.$router.push('/').catch((errr) => { })
      } else {
        this.showform('showLogin')
        this.setAuthenticated(false)
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcInvalidAuthentication,
          confirmButtonText: this.i18n['login'].tcOk,
        }).then((result) => {
          this.closeLoader()
          return
        })
      }
    },
    async retrylogin() {
      this.showform('showLogin')
      this.setAuthenticated(false)
    },
    async registerStep1() {
      // save the camp and user ids to the user store
      this.showLoader()
      var payload = {
        memberno: this.memberno,
        campno: this.campno,
      }
      let result = await this.getMembersAuthQuestionsAndAnswers(payload)
      if (result == false) {
        let result = await this.getUserVerification(payload)
        if (result == true && this.userVerification.ind_key) {
          this.register_data.emailaddress = this.userVerification.usr_login_id
          this.register_data.firstname = this.userVerification.ind_first_name
          this.register_data.lastname = this.userVerification.ind_last_name
          this.register_data.memberno = this.userVerification.ind_account_id
          this.register_data.campno = this.userVerification.cmp_number
          this.user_data.location = this.userVerification.org_name
          await this.setUserDataFromUserVerification()
          await this.setUser(this.user_data)
          await this.getAuthQuestions()
          await this.loadEmailFormOptions()
          this.showform('showRegister')
          this.closeLoader()
        } else {
          this.$swal({
            icon: 'error',
            text: this.i18n['login'].tcInvalidAuthentication,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.closeLoader()
            return
          })
        }
      } else {
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcYouHaveAlreadyRegistered,
          confirmButtonText: this.i18n['login'].tcOk,
        }).then((result) => {
          this.closeLoader()
          return
        })
      }
    },
    validateRegistration() {
      let rtn = true
      if (
        !this.register_data.question1 ||
        !this.register_data.question2 ||
        !this.register_data.question3 ||
        !this.register_data.answer1 ||
        !this.register_data.answer2 ||
        !this.register_data.answer3 ||
        !this.register_data.emt_key ||
        !this.register_data.emailaddress ||
        !this.register_data.confirmemailaddress ||
        !this.register_data.password ||
        !this.register_data.confirmpassword
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcRegistrationInfoRequired,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }

      if (rtn == true && this.register_data.emailaddress !== this.register_data.confirmemailaddress) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcRegistrationEmailsDoNotMatch,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }

      if (rtn == true && this.passwordValidation.valid === false) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcFailedPasswordConstraints,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }

      if (rtn == true && this.register_data.password !== this.register_data.confirmpassword) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcRegistrationPasswordsDoNotMatch,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }

      return rtn
    },
    async registerStep2() {
      this.showLoader()
      if (this.validateRegistration()) {
        var payload = {
          ind_key: this.user_data.ind_key,
          rqa_question1: this.register_data.question1,
          rqa_answer1: this.register_data.answer1,
          rqa_question2: this.register_data.question2,
          rqa_answer2: this.register_data.answer2,
          rqa_question3: this.register_data.question3,
          rqa_answer3: this.register_data.answer3,
          emt_key: this.register_data.emt_key,
          email: this.register_data.emailaddress,
          password: this.register_data.password,
        }
        let result = await this.getUserRegistered(payload)
        if (result == true) {
          this.$swal({
            icon: 'success',
            text: this.i18n['login'].tcThankYouForRegistering,
            showCancelButton: false,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.showform('showLogin')
            this.closeLoader()
            return
          })
        } else {
          this.$swal({
            icon: 'error',
            text: this.i18n['login'].tcPleaseTryDifferent,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.closeLoader()
            return
          })
        }
      } else {
        this.closeLoader()
        return false
      }
    },
    async recoverusernameStep1() {
      this.showLoader()
      var payload = {
        memberno: this.recoverusername_data.memberno,
        campno: this.recoverusername_data.campno,
      }
      let result = await this.getMembersAuthQuestionsAndAnswers(payload)
      if (result == true) {
        let result = await this.getUserVerification(payload)
        if (result == true && this.userVerification.ind_key) {
          this.recoverusername_data.emailaddress = this.userVerification.usr_login_id
          this.recoverusername_data.firstname = this.userVerification.ind_first_name
          this.recoverusername_data.lastname = this.userVerification.ind_last_name
          this.recoverusername_data.memberno = this.userVerification.ind_account_id
          this.recoverusername_data.campno = this.userVerification.cmp_number
          await this.getAuthQuestions()
          this.recoverusername_data.chosenQuestion = Math.floor(Math.random() * 3) + 1
          let qVal = 0
          switch (this.recoverusername_data.chosenQuestion) {
            case 1:
              qVal = this.membersAuthQuestionsAndAnswers.rqa_question1
              break
            case 2:
              qVal = this.membersAuthQuestionsAndAnswers.rqa_question2
              break
            case 3:
              qVal = this.membersAuthQuestionsAndAnswers.rqa_question3
              break
          }
          this.recoverusername_data.question = this.convertedAuthQuestions.questions.filter((item) => {
            return item.value == qVal
          })[0].text
          await this.setUserDataFromUserVerification()
          await this.setUser(this.user_data)
          this.showform('showRecoverUsername2')
          this.closeLoader()
        } else {
          this.$swal({
            icon: 'error',
            text: this.i18n['login'].tcInvalidAuthentication,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.closeLoader()
            return
          })
        }
      } else {
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcYouHaveNeverRegistered,
          confirmButtonText: this.i18n['login'].tcOk,
        }).then((result) => {
          this.closeLoader()
          return
        })
      }
    },
    validateRecoverUsername() {
      let rtn = true
      if (
        this.recoverusername_data.question === null ||
        this.recoverusername_data.answer === '' ||
        this.recoverusername_data.emailaddress === '' ||
        this.recoverusername_data.memberno === '' ||
        this.recoverusername_data.campno === ''
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcInformationIsRequired,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }
      return rtn
    },
    async recoverusernameStep2() {
      this.showLoader()
      if (this.validateRecoverUsername()) {
        var payload = {
          ind_key: this.user_data.ind_key,
          answer: this.recoverusername_data.answer,
          question_number: this.recoverusername_data.chosenQuestion,
          memberno: this.recoverusername_data.memberno,
          email: this.recoverusername_data.emailaddress,
        }
        let result = await this.getUserVerifyQuestionAnswer(payload)
        if (result == true) {
          this.$swal({
            icon: 'success',
            text: this.i18n['login'].tcYourUserNameRecovered,
            showCancelButton: false,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.userid = this.recoverusername_data.emailaddress
            this.showform('showLogin')
            this.closeLoader()
            return
          })
        } else {
          this.$swal({
            icon: 'error',
            text: this.i18n['login'].tcInvalidAuthentication,
            showCancelButton: false,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.closeLoader()
            return
          })
        }
      } else {
        this.closeLoader()
        return false
      }
    },
    async resetpasswordStep1() {
      this.showLoader()
      var payload = {
        memberno: this.resetpassword_data.memberno,
        campno: this.resetpassword_data.campno,
      }
      let result = await this.getMembersAuthQuestionsAndAnswers(payload)
      if (result == true) {
        let result = await this.getUserVerification(payload)
        if (result == true && this.userVerification.ind_key) {
          this.resetpassword_data.emailaddress = this.userVerification.usr_login_id
          this.resetpassword_data.firstname = this.userVerification.ind_first_name
          this.resetpassword_data.lastname = this.userVerification.ind_last_name
          this.resetpassword_data.memberno = this.userVerification.ind_account_id
          this.resetpassword_data.campno = this.userVerification.cmp_number
          await this.getAuthQuestions()
          this.resetpassword_data.chosenQuestion = Math.floor(Math.random() * 3) + 1
          let qVal = 0
          switch (this.resetpassword_data.chosenQuestion) {
            case 1:
              qVal = this.membersAuthQuestionsAndAnswers.rqa_question1
              break
            case 2:
              qVal = this.membersAuthQuestionsAndAnswers.rqa_question2
              break
            case 3:
              qVal = this.membersAuthQuestionsAndAnswers.rqa_question3
              break
          }
          this.resetpassword_data.question = this.convertedAuthQuestions.questions.filter((item) => {
            return item.value == qVal
          })[0].text
          await this.setUserDataFromUserVerification()
          await this.setUser(this.user_data)
          this.showform('showResetPassword2')
          this.closeLoader()
        } else {
          this.$swal({
            icon: 'error',
            text: this.i18n['login'].tcInvalidAuthentication,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.closeLoader()
            return
          })
        }
      } else {
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcYouHaveNotRegistered,
          confirmButtonText: this.i18n['login'].tcOk,
        }).then((result) => {
          this.closeLoader()
          return
        })
      }
    },
    validateResetPassword() {
      let rtn = true
      if (
        this.resetpassword_data.question === null ||
        this.resetpassword_data.answer === '' ||
        this.resetpassword_data.password === null ||
        this.resetpassword_data.confirmpassword === '' ||
        this.resetpassword_data.emailaddress === '' ||
        this.resetpassword_data.memberno === '' ||
        this.resetpassword_data.campno === ''
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcInformationRequiredToReset,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }
      if (rtn == true && this.resetpassword_data.password != this.resetpassword_data.confirmpassword) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcPasswordsDoNotMatch,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }
      if (rtn == true && this.resetpasswordValidation.valid === false) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.i18n['login'].tcFailedPasswordConstraints,
          confirmButtonText: this.i18n['login'].tcOk,
        })
      }
      return rtn
    },
    async resetpasswordStep2() {
      this.showLoader()
      if (this.validateResetPassword()) {
        var payload = {
          ind_key: this.user_data.ind_key,
          answer: this.resetpassword_data.answer,
          question_number: this.resetpassword_data.chosenQuestion,
          memberno: this.resetpassword_data.memberno,
          email: this.resetpassword_data.emailaddress,
          password: this.resetpassword_data.password,
        }
        if (await this.getUserPasswordReset(payload)) {
          this.$swal({
            icon: 'success',
            text: this.i18n['login'].tcYourPasswordHasBeenReset,
            showCancelButton: false,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.showform('showLogin')
            this.closeLoader()
            return
          })
        } else {
          this.$swal({
            icon: 'error',
            text: this.i18n['login'].tcInvalidAuthentication,
            confirmButtonText: this.i18n['login'].tcOk,
          }).then((result) => {
            this.closeLoader()
            return
          })
        }
      } else {
        this.closeLoader()
        return false
      }
    },
    async redirectUser() {
      let redirectPath = this.loginRedirectPath !== '' ? this.loginRedirectPath : '/'
      // This is to account for bookmarks from the old site
      if (redirectPath.indexOf('.aspx') !== -1) {
        redirectPath = '/'
      }
      // This is to setup the required info if routing to the Member Dues page
      if (redirectPath.indexOf('programs/mb/member-dues') !== -1) {
        this.setSelectedIndividualKey(this.userLogin.indkey)
        await this.setLoadingStatus(true)
        await this.loadIndividualProfile()
        await this.setLoadingStatus(false)
      }
      this.setLoginRedirectPath('')
      this.$router.push(redirectPath)
    },
  },
  async created() {
    await this.getViewTranslations('login', 'en')
  },
  mounted() {
    if (this.userid) {
      document.getElementById("password").focus();
    } else {
      document.getElementById("user_id").focus();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

.VuePassword {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px;
}

.notify-warning {
  color: #d8000c;
  background-color: #ffd2d2;
  padding: 10px 10px;
  text-align: center;
}

#header {
  background-color: #003946;
  height: 128px;
}

#header .container {
  padding-top: 29px;
  padding-bottom: 29px;
}

.lower {
  text-transform: lowercase;
}

.upper {
  text-transform: uppercase;
}

.caps {
  text-transform: capitalize;
}

.btn {
  padding: 12px 35px;
  border: none;
  border-radius: 0;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 22px;
  text-transform: uppercase;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
  padding: 0;
}

.fun-link {
  font-weight: 600;
  text-transform: none !important;
  width: 160px;
}

.retry-link {
  font-weight: 600;
  text-transform: none !important;
}

input {
  font-family: 'Open Sans', sans-serif !important;
}

.bold {
  font-weight: bold;
}

.logotext {
  font-family: 'DIN 1451 W01 Engschrift';
  font-size: 3.5rem;
  text-align: right;
  position: relative;
}

.logotext1 {
  color: white;
}

.logotext2 {
  color: #fbc301 !important;
}

.maincard {
  margin-top: 3rem;
}

.cardtopsection {
  background-color: #ffffff;
  color: #000000;
}

.cardbottomsection {
  background-color: #fbc301;
  color: #000000;
}

.signin {
  font-family: 'DIN 1451 W01 Engschrift';
  font-size: 3rem;
  width: 100%;
  text-align: center;
}

.nreg {
  font-family: 'DIN 1451 W01 Engschrift';
  font-size: 2.3rem;
  width: 100%;
  text-align: center;
}

.nregdesc {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.btnsignin {
  font-family: 'DIN 1451 W01 Engschrift';
  font-size: 1.5rem !important;
  letter-spacing: 1px;
  border: 0 !important;
  border-radius: 0;
  margin-top: 10px !important;
}

.btnsignin:hover {
  color: #fbc301 !important;
}

.btngreyblue {
  background-color: #6d7278 !important;
  font-family: 'DIN 1451 W01 Engschrift';
  font-size: 1.5rem !important;
  letter-spacing: 1px;
  border: 0 !important;
  border-radius: 0;
}

.btngreyblue:hover {
  color: #343a40 !important;
}

.btngreyyellow {
  background-color: #6d7278 !important;
  font-family: 'DIN 1451 W01 Engschrift';
  font-size: 1.5rem !important;
  letter-spacing: 1px;
  border: 0 !important;
  color: #ffffff;
  border-radius: 0;
  margin-top: 10px !important;
}

.btngreyyellow:hover {
  color: #fbc301 !important;
}

#memberno::placeholder {
  color: #000000;
  opacity: 1;
  /* Firefox */
}

#campno::placeholder {
  color: #000000;
  opacity: 1;
  /* Firefox */
}

.remembermediv {
  padding: 0.375rem 0 !important;
  display: flex;
  align-content: flex-start;
  vertical-align: top;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.remembermelabel {
  font-weight: 600;
  color: #6d7278 !important;
  order: 2;
}

.page-body {
  padding: 0 15px !important;
}

.col-regsidecol {
  background-color: #fbc301;
}

.col-regmaincol {
  margin: auto;
  background-color: #fbc301;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-left: 0;
  margin-right: 0;
}

.hints {
  max-width: 400px;
  padding: 1em;
  background: whitesmoke;
  margin: 1em 0;
  font-size: 0.9em !important;
  color: darken(#d4dedf, 50%);

  h2 {
    font-size: 1.2em !important;
    font-weight: 600;
  }

  p {
    margin: 0;
    padding-left: 1em;
    font-size: 0.9em !important;
    line-height: 1.1em !important;

    &::before {
      content: '>';
      font-size: 0.9em;
      margin-right: 6px;
      display: inline-block;
    }
  }

  input[type='text'].login-input {
    min-width: 100% !important;
    width: 100% !important;
  }
}

input[type='checkbox'].remembermecheckbox {
  order: 1;
  width: 1rem;
  height: 1.25rem;
  left: 0;
}

.card-text {
  margin-bottom: 16px;
}

.hint {
  &-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  &-enter-active {
    transition: 300ms;
  }

  &-leave-active {
    transition: 400ms;
  }
}

@media screen and (max-width: 960px) {
  .g-select {
    max-width: 100% !important;
    padding: 10px 15px !important;
  }

  .row .row {
    display: block;
  }

  .btnsignin {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {

  /* For mobile phones: */
  // [class*="col-"] {
  //   width: 100%;
  // }
  .maincard {
    padding-left: 0;
    padding-right: 0;
  }

  .g-select {
    max-width: 100% !important;
    padding: 10px 15px !important;
  }

  .card-text {
    font-size: 90%;
    letter-spacing: 0.5px;
    line-height: 20px;
  }

  .nregdesc {
    font-size: 90%;
  }

  .memberdata {
    margin-right: -45px;
  }

  .form-control {
    font-size: 11px;
  }
}

#footer {
  background-color: #ffffff !important;

  .container {
    padding-top: 48px;
    padding-bottom: 26px;
  }

  .logo {
    margin-bottom: 26px;

    @include breakpoint(sm) {
      display: none;
    }
  }

  .mobile-logo {
    display: flex;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-wrap: nowrap;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }

  .col {
    @include breakpoint(sm) {
      max-width: none !important;
      width: 50% !important;
      flex: 0 0 50% !important;
      padding: 0 2rem !important;
    }

    &:nth-of-type(1) {
      flex: 1 1 auto;
    }

    &:nth-of-type(2) {
      flex: 0 0 auto;
      max-width: 282px;
      padding-left: 0;
    }

    &:nth-of-type(3) {
      flex: 0 0 auto;
      max-width: 284px;
      padding-left: 0;
    }

    &:nth-of-type(4) {
      flex: 0 0 auto;
      max-width: 190px;
      padding-left: 0;
    }
  }
}
</style>